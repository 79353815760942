import { useRef, useState } from "react";

import { TextField, Card, Box, Button } from "@mui/material";
import { Typography, Collapse, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addNewUser, logInUser } from "../../API/AuthAPI";
import { formatErrorCode } from "../../utils/formatErrorCode";

const UserLogin = (props) => {
  const email = useRef();
  const password = useRef();
  const userName = useRef();
  const [isLogInUI, setUIState] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState("");

  const changeUi = () => {
    setUIState((prevState) => {
      return prevState ? false : true;
    });
  };

  const signUp = async () => {
    try {
      await addNewUser(
        userName.current.value,
        email.current.value,
        password.current.value
      );
    } catch (e) {
      setErrorMsg(formatErrorCode(e ?? "Something went wrong :("));
      setShowAlert(true);
    }
  };

  const signIn = async () => {
    try {
      await logInUser(email.current.value, password.current.value);
    } catch (e) {
      setErrorMsg(formatErrorCode(e ?? "Something went wrong :("));
      setShowAlert(true);
    }
  };

  const Login1 = () => {
    email.current.value = "admin1@gmail.com";
    password.current.value = "adminrk03";
    signIn();
  };
  const Login2 = () => {
    email.current.value = "admin2@admin.com";
    password.current.value = "adminrk03";
    signIn();
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <h1 style={{ textAlign: "center", marginTop: "5rem" }}> FireChat 🔥</h1>
      <Card
        variant="outlined"
        sx={{
          width: "22rem",
          maxWidth: "94vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0.6rem",
          height: isLogInUI ? "25rem" : "30rem",
          position: "absolute",
          top: "0",
          bottom: "0",
          margin: "auto 0",
        }}
      >
        <Typography variant="h4" sx={{ margin: "1.6rem" }}>
          {isLogInUI ? "Sign In" : "Sign Up"}
        </Typography>
        {!isLogInUI && (
          <TextField
            id="outlined-basic"
            label="Your Name"
            variant="outlined"
            sx={{ width: "90%", margin: "0.5rem" }}
            inputRef={userName}
          />
        )}

        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="email"
          sx={{ width: "90%", margin: "0.5rem" }}
          inputRef={email}
        />
        <TextField
          id="outlined-basic"
          label="Password"
          variant="outlined"
          type="password"
          inputRef={password}
          sx={{ width: "90%", margin: "0.5rem" }}
        />
        <span
          style={{ color: "#1976D2", cursor: "pointer", marginTop: "0.5rem" }}
          onClick={changeUi}
        >
          {isLogInUI ? "New User? Register Now!" : "Existing User? Login"}
        </span>
        <span
          style={{
            color: "#1976D2",
            fontSize: "small",
            cursor: "pointer",
            marginTop: "0.8rem",
          }}
          onClick={Login1}
        >
          Login with test credentials - 1
        </span>
        <span
          style={{
            color: "#1976D2",
            fontSize: "small",
            cursor: "pointer",
            marginTop: "0.25rem",
          }}
          onClick={Login2}
        >
          Login with test credentials - 2
        </span>
        <Button
          sx={{
            width: "88%",
            borderRadius: "12px",
            margin: "1rem 0 1rem 0",
          }}
          variant="contained"
          onClick={isLogInUI ? signIn : signUp}
        >
          {isLogInUI ? "Sign In" : "Sign Up"}
        </Button>
      </Card>
      <Collapse
        in={showAlert}
        sx={{
          position: "fixed",
          bottom: 0,
        }}
      >
        <Alert
          severity="error"
          sx={{
            fontSize: { laptop: "1rem", mobile: "1.2rem" },
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {ErrorMsg}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default UserLogin;
