import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { nanoid } from "nanoid";
import {
  arrayUnion,
  collection,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import db from "../firebase";
import { getAuth } from "firebase/auth";

const useContactList = () => {
  const currentUserRef = doc(db, `UserList/${getAuth().currentUser.uid}`);
  const contactList = useDocument(currentUserRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return contactList;
};

const useUsersList = () => {
  return useCollection(collection(db, "UserList"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

const removeUserFromList = (updatedContactList, userToBeDeleted) => {
  const currentUserRef = doc(db, `UserList/${getAuth().currentUser.uid}`);
  updateDoc(
    currentUserRef,
    {
      contactList: [...updatedContactList],
      deleted: arrayUnion({
        ...userToBeDeleted,
      }),
    },
    { merge: true }
  );
};

const addUserToList = (
  userToBeAdded,
  doesUserExist,
  wasUserAdded,
  wasUserDeleted
) => {
  const currentUserId = getAuth().currentUser.uid;
  const currentUserRef = doc(db, `UserList/${currentUserId}`);
  let chatID = nanoid();
  let userDeletedData = wasUserDeleted;
  if (
    userToBeAdded &&
    doesUserExist &&
    !wasUserAdded &&
    currentUserId !== userToBeAdded
  ) {
    if (userDeletedData) {
      updateDoc(
        currentUserRef,
        {
          contactList: arrayUnion({
            uid: userToBeAdded,
            chatID: userDeletedData.chatID,
          }),
        },
        { merge: true }
      );
    } else {
      updateDoc(
        currentUserRef,
        { contactList: arrayUnion({ uid: userToBeAdded, chatID: chatID }) },
        { merge: true }
      );
      updateDoc(
        doc(db, `UserList/${userToBeAdded}`),
        { contactList: arrayUnion({ uid: currentUserId, chatID: chatID }) },
        { merge: true }
      );
      setDoc(doc(db, `Messages/${chatID}`), { messages: [] });
    }
    return true;
  } else {
    return false;
  }
};

export { useContactList, useUsersList, removeUserFromList, addUserToList };
