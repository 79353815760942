export const formatErrorCode = (code) => {
  return (
    code
      .toString()
      .replace("auth/", "")
      .replaceAll("-", " ")
      .charAt(0)
      .toUpperCase() +
    code.toString().replace("auth/", "").replaceAll("-", " ").slice(1)
  );
};
