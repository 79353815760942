import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import db from "../firebase";
import { doc, setDoc } from "firebase/firestore";
const auth = getAuth();

const addNewUser = async (userName, email, password) => {
  if (!userName) throw new Error("Enter a name");
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await setDoc(doc(db, `UserList/${userCredential.user.uid}`), {
      name: userName,
      contactList: [],
    });
  } catch (error) {
    throw new Error(error.code ?? error);
  }
};

const logInUser = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    throw new Error(error.code);
  }
};

export { addNewUser, logInUser };
